import UploadComponent from './index.vue';
// import ImgUploadComponent from './imgUpload.vue';
const Upload = {
  install(Vue) {
    Vue.component(UploadComponent.name, UploadComponent)
  }
}

// const ImgUpload = {
//   install(Vue) {
//     Vue.component(ImgUploadComponent.name, ImgUploadComponent)
//   }
// }


export default Upload;