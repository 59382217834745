<template>
  <div>
    <!-- begin login-cover -->
    <div class="login-cover">
      <div
        class="login-cover-image"
        v-bind:style="{ backgroundImage: 'url(' + bg.activeImg + ')' }"
      ></div>
      <div class="login-cover-bg"></div>
    </div>
    <!-- end login-cover -->

    <!-- begin login -->
    <div class="login login-v2" data-pageload-addclass="animated fadeIn">
      <!-- begin brand -->
      <div class="login-header">
        <div class="brand">
          <!-- <img src="/assets/img/cover/dqh.png"/> -->
          <b>东钱湖</b>企业安全码 2.0
          <!-- <small>Vue 前端开发框架</small> -->
        </div>
        <div class="icon">
          <i class="fa fa-lock"></i>
        </div>
      </div>
      <!-- end brand -->
      <!-- begin login-content -->
      <div class="login-content">
        <form v-on:submit="checkForm" method="POST" class="margin-bottom-0">
          <div class="form-group m-b-20">
            <input
              type="text"
              class="form-control form-control"
              v-model="loginform.username"
              placeholder="请输入帐号"
              required
            />
          </div>
          <div class="form-group m-b-20">
            <input
              type="password"
              class="form-control form-control"
              v-model="loginform.password"
              placeholder="请输入密码"
              required
            />
          </div>
          <div class="checkbox checkbox-css m-b-20">
            <input
              type="checkbox"
              id="remember_checkbox"
              v-model="loginform.remember"
            />
            <label for="remember_checkbox"> 记住密码 </label>
          </div>
          <div class="login-buttons">
            <button
              type="submit"
              v-bind:class="loading ? 'disabled' : ''"
              class="btn btn-success btn-block btn"
            >
              <i class="fas fa-fw m-r-10 fa-lock"></i
              >{{ loading ? "登录中..." : "登  录" }}
            </button>
          </div>
          <div class="m-t-20 p-t-40 p-l-40">
            Full version <a href="javascript:;"> </a> _1.1.0 &nbsp;&nbsp;Date_2022-06-24
          </div>
          <!-- <div class="m-t-20">
						Not a member yet? Click <a href="javascript:;">here</a> to register.
					</div> -->
        </form>
      </div>
      <!-- end login-content -->
    </div>
    <!-- end login -->
    <!-- begin login-bg -->
    <ul class="login-bg-list clearfix">
      <li v-bind:class="{ active: bg.bg1.active }">
        <a
          href="javascript:;"
          v-on:click="select('bg1')"
          style="background-image: url(/assets/img/login-bg/login-bg-17.jpg)"
        ></a>
      </li>
      <li v-bind:class="{ active: bg.bg2.active }">
        <a
          href="javascript:;"
          v-on:click="select('bg2')"
          style="background-image: url(/assets/img/login-bg/login-bg-16.jpg)"
        ></a>
      </li>
      <li v-bind:class="{ active: bg.bg3.active }">
        <a
          href="javascript:;"
          v-on:click="select('bg3')"
          style="background-image: url(/assets/img/login-bg/login-bg-15.jpg)"
        ></a>
      </li>
      <li v-bind:class="{ active: bg.bg4.active }">
        <a
          href="javascript:;"
          v-on:click="select('bg4')"
          style="background-image: url(/assets/img/login-bg/login-bg-14.jpg)"
        ></a>
      </li>
      <li v-bind:class="{ active: bg.bg5.active }">
        <a
          href="javascript:;"
          v-on:click="select('bg5')"
          style="background-image: url(/assets/img/login-bg/login-bg-13.jpg)"
        ></a>
      </li>
      <li v-bind:class="{ active: bg.bg6.active }">
        <a
          href="javascript:;"
          v-on:click="select('bg6')"
          style="background-image: url(/assets/img/login-bg/login-bg-12.jpg)"
        ></a>
      </li>
    </ul>
    <!-- end login-bg -->
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import JSEncrypt from "jsencrypt";
import { userApi } from "@/api/system/userApi";
export default {
  created() {
    PageOptions.pageEmpty = true;

    //如果记住了密码，则读取帐号信息
    var remember = localStorage.getItem("remember") || false;
    if (remember) {
      var user = JSON.parse(
        this.decrypt(localStorage.getItem(this.accountKey)) || ""
      );
      this.loginform.username = user.username;
      this.loginform.password = user.password;
      this.loginform.remember = user.remember;
    }
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    next();
  },
  data() {
    return {
      accountKey: "accountKey",
      loading: false,
      bg: {
        activeImg: "/assets/img/login-bg/login-bg-17.jpg",
        bg1: {
          active: true,
          img: "/assets/img/login-bg/login-bg-13.jpg",
        },
        bg2: {
          active: false,
          img: "/assets/img/login-bg/login-bg-16.jpg",
        },
        bg3: {
          active: false,
          img: "/assets/img/login-bg/login-bg-15.jpg",
        },
        bg4: {
          active: false,
          img: "/assets/img/login-bg/login-bg-14.jpg",
        },
        bg5: {
          active: false,
          img: "/assets/img/login-bg/login-bg-13.jpg",
        },
        bg6: {
          active: false,
          img: "/assets/img/login-bg/login-bg-12.jpg",
        },
      },
      loginform: {
        username: "",
        password: "",
        remember: false,
      },
      rsaPublicKey: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr0uH0tiZvVsqongg0HWU
Es/i1vn1BaBG4h/wSIVf/pxtjNBUfdXonH8Xx04ufk+sNVc3vTvNa5SYx8vhoAEv
LeS0eSOlE3iMYzbuY1410KpGh+CdKxCqV9pEEXwKjbiLD7StOQmzgyL5zUPjKblU
2XlYHWeAm+oyX8X5c3Eny6xqzyHPVk2sHDJrEAAb86GBJGX4hOy1tYQn90LJXoZ5
vYdXkvR09gkZ0x634cB8cNBJ6MXzHlyoLenb657lMdNa979QzDO4Xa29MvSUvyhG
TyNitRyFOu5In71TTCWH9vBDuFhOw/8wbFljkesFyNrQ2/5zYo4jwcZmhk/raWjA
5QIDAQAB`, //公钥
      privateKey: `MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCvS4fS2Jm9Wyqi
eCDQdZQSz+LW+fUFoEbiH/BIhV/+nG2M0FR91eicfxfHTi5+T6w1Vze9O81rlJjH
y+GgAS8t5LR5I6UTeIxjNu5jXjXQqkaH4J0rEKpX2kQRfAqNuIsPtK05CbODIvnN
Q+MpuVTZeVgdZ4Cb6jJfxflzcSfLrGrPIc9WTawcMmsQABvzoYEkZfiE7LW1hCf3
Qslehnm9h1eS9HT2CRnTHrfhwHxw0EnoxfMeXKgt6dvrnuUx01r3v1DMM7hdrb0y
9JS/KEZPI2K1HIU67kifvVNMJYf28EO4WE7D/zBsWWOR6wXI2tDb/nNijiPBxmaG
T+tpaMDlAgMBAAECggEAQRnI6Ioelo9MByib7y8rfc0Mzo/n6l361THNV/GIlBNe
MASSzGTrzWr51r/3TluidfDdN38FLCX0k4bpVTX7YozkZXTScqe7Y4FcCTVMwSDr
rrzBQeQfjx71VpT9imJ16rBMALnUnCgaI5xJug4lL9r72Xe98KmKab0phjEI+X+C
Y+5jQnSzvQ19g2EB6urN++hE83kFvQUXJC/meXhFIrt91k6n7rrWjSqn6RlRcxtD
jVs2rwX807Sw0DoZTbkm8mIhAJZO6K1sGUoGKZcUHMRs6lDp4CUd/zQYvKswgurI
UXQ7DsmdnGl6FVTvsmn4WMMoWcBh5h+2J+32bsuHIQKBgQDeueHz7Pkpiojxrqqt
dlLaNEacBU1Z5lJQvKMeCj+nmZDVbsoFN2PRNNOIoTmT/GVWM2WFyzsqbSYtVwBj
64vvLE3cCE7VF5QCdu59hEH31Wv5lqJrSimZu3FRwyklp5jKz8KKeMGWSpQWxra3
2JDvtyqevzhn9FuKVk+nve653QKBgQDJe6fugOFrQMEflq0Qtf4AF6hJlEURrWbf
DTITAJDXQ0TJ4HdSP3aUkckV3SutxRLVvxZbsaOvkeabatz8x6hGcydJ+FWFWOym
tgb1gKnkyo2KmdMSaK9qAWDikoFqP/4hDGIDKQ9sZiB8lwt7wHSuHOQ6Ji7PTuOI
A9LirKpmqQKBgFoDGZQxM0lcmZfDFWthJH7XHao0YlxwvteuROYBElSztVrsmWye
RBLqnIrdTZ3HJVU/FEqMJe8xiXpNbPT03urcJuND4EgybTjJw/yqFNcKQ8bs39so
b8OXg2adzuVO5Gsip+a6gtIwdcT2riWCuqCGkAz0DuHEWbKAy1RpaC9pAoGAU/j3
io2oZXz9gF7aDmbrT7qMZu4mwzFT+I6VWhoYQ79DgifNUzgkAdOozTX6qFfEe9VC
SkEFCX1zcT7ej3PfDE2eCFQ5wHPJXcnrqJJRPIu25hcqQzfiM/oTGRsaWOAG2FtC
1jJcvT7rN0H0hUU23AIqlJmpVLl6VwvQ7qwr8NECgYEAnvAHIsBrBCD8wI60Xvvg
Zx5rSG4WTd2CjUBKWspzUVDdIhrP8mZx1X7zksCJnwC/AAP++gXb0LAMMKo/QJ80
orDd9coL5ubLMzW3/uOdHyfx1jO1xaRbBZmnB87J+kkPTOypFK85YhR1YLRGuYHG
4rERph8/GGaJQP9dxVEBa0A=`,
    };
  },
  mounted() {
    // this.d1=this.getD1();
  },
  methods: {
    checkForm: function(e) {
      e.preventDefault();
      // if (!this.loading) {
      this.loading = true;
      const loginParams = {
        username: this.loginform.username,
        password: this.loginform.password,
      };
      let that = this;
      //登录成功处理//
      userApi
        .login(loginParams)
        .then((res) => that.loginSuccess(that, res))
        .catch((err) => that.requestFailed(err))
        .finally(() => {
          that.loading = false;
          // state.loginBtn = false;
        });
      // }
    },
    loginSuccess(that, res) {
      if (res.success == false && res.code == 500) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: "您输入的帐号或密码有错误!",
        });
      } else {
        // console.log("loginSuccess", res);
        //缓存Token，【Abp框架】登录时需要基于Toekn获取用户信息
        that.$store.commit("setToken", res.data.token);

        let userRole = res.data.role;
        let userId = res.data.id;

        //设置用户状态
        const userInfo = {
          username: res.data.userName,
          token: res.data.token,
          role: userRole,
          userId: userId,
        };
        //把userInfo放到缓存里
        that.$store.commit("setUserInfo", userInfo);
        //吧userRole放到缓存里
        that.$store.commit("setUserRole", userRole);
        //处理记住密码
        localStorage.setItem("remember", that.loginform.remember);
        localStorage.removeItem(that.accountKey);
        if (that.loginform.remember) {
          var encrypted = that.encrypt(JSON.stringify(that.loginform));
          localStorage.setItem(that.accountKey, encrypted);
        }

        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "登录成功，欢迎 " + userInfo.username,
        });

        //登录首页
        that.$router.push({ path: "/" });
      }
    },
    requestFailed(err) {
      if (err.statuscode == 401) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: "您输入的帐号或密码有错误!",
        });
        // this.$swal({
        //   title: "登录失败，请重试！",
        //   text: "您输入的帐号或密码有错误!",
        //   type: 'warning',
        //   buttonsStyling: false,
        //   confirmButtonText: "确定",
        //   confirmButtonClass:"btn btn-success"
        // });
      }
    },
    select: function(x) {
      this.bg.bg1.active = false;
      this.bg.bg2.active = false;
      this.bg.bg3.active = false;
      this.bg.bg4.active = true;
      this.bg.bg5.active = false;
      this.bg.bg6.active = false;

      switch (x) {
        case "bg1":
          this.bg.bg1.active = true;
          this.bg.activeImg = this.bg.bg1.img;
          break;
        case "bg2":
          this.bg.bg2.active = true;
          this.bg.activeImg = this.bg.bg2.img;
          break;
        case "bg3":
          this.bg.bg3.active = true;
          this.bg.activeImg = this.bg.bg3.img;
          break;
        case "bg4":
          this.bg.bg4.active = true;
          this.bg.activeImg = this.bg.bg4.img;
          break;
        case "bg5":
          this.bg.bg5.active = true;
          this.bg.activeImg = this.bg.bg5.img;
          break;
        case "bg6":
          this.bg.bg6.active = true;
          this.bg.activeImg = this.bg.bg6.img;
          break;
      }
    },
    //加密
    encrypt(s) {
      let jse = new JSEncrypt();
      jse.setPublicKey(this.rsaPublicKey);
      let encrypted = jse.encrypt(s);
      return encrypted;
    },
    // 解密
    decrypt(s) {
      let decrypt = new JSEncrypt();
      decrypt.setPrivateKey(this.privateKey);
      let encrypted = decrypt.decrypt(s);
      return encrypted;
    },
  },
};
</script>
