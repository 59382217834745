// 本项目使用Abp框架的验证方式，其他后台需要自行调整
import {
    propertyService
} from '@/api/utils/request'

const apiUrl = {
    loginUrl: '/api/account/login',
    ChargePassword: '/api/account/change-password',
    CreateCompany:'/api/business/create',
    // Login: '/auth/login',
    Logout: '/auth/logout',
    ForgePassword: '/auth/forge-password',
    Register: '/auth/register',
    twoStepCode: '/auth/2step-code',
    SendSms: '/account/sms',
    SendSmsErr: '/account/sms_err',
    // get my info
    UserInfo: '/user/info',
    UserMenu: '/user/nav'
}

const userApi = {
    // 登录(获取Toekn)
    login(parameter) {
        return propertyService({
            // url: '/connect/token',
            url: apiUrl.loginUrl,
            method: 'post',
            params: parameter,
            // headers: {
            //     'content-type': 'application/x-www-form-urlencoded'
            // }
        })
    },

    //修改密码
    chargePassword(parameter) {
        return propertyService({
            // url: '/connect/token',
            url: apiUrl.ChargePassword,
            method: 'post',
            params: parameter,
            needToken: true
        })
    },
    //保存企业信息
    CreateCompany(parameter) {
        return propertyService({
            url: apiUrl.CreateCompany,
            method: 'post',
            params: parameter,
            needToken: true
        })
    },

    // 获取当前登录用户信息
    myprofile() {
        return propertyService({
            url: '/api/identity/my-profile',
            method: 'get',
            needToken: true
        })
    },

    getSmsCaptcha(parameter) {
        return propertyService({
            url: apiUrl.SendSms,
            method: 'post',
            data: parameter
        })
    },

    getInfo() {
        return propertyService({
            url: apiUrl.UserInfo,
            method: 'get',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    },

    getCurrentUserNav() {
        return propertyService({
            url: apiUrl.UserMenu,
            method: 'get'
        })
    },
    logout() {
        return propertyService({
            url: apiUrl.Logout,
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    },

    /**
     * get user 2step code open?
     * @param parameter {*}
     */
    get2step(parameter) {
        return propertyService({
            url: apiUrl.twoStepCode,
            method: 'post',
            data: parameter
        })
    },
}

export {
    userApi
}