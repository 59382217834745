<script>
const pageOptions = {
  pageSidebarMinified: false,
  pageContentFullWidth: false,
  pageContentInverseMode: false,
  pageContentFullHeight: true,
  pageWithLanguageBar: false,
  pageWithFooter: false,
  pageWithoutSidebar: false,
  pageWithRightSidebar: false,
  pageWithTwoSidebar: true,
  pageWithWideSidebar: true,
  pageWithLightSidebar: false,
  pageWithMegaMenu: false,
  pageWithTopMenu: false,
  pageSidebarTransparent: false,
  pageEmpty: false,
  pageMobileSidebarToggled: false,
  pageMobileRightSidebarToggled: false,
  pageMobileTopMenu: true,  
  pageMobileMegaMenu: false,
  pageRightSidebarToggled: false,
  pageRightSidebarCollapsed: true,
  pageSidebarSearch: false,
  pageBodyScrollTop: 110,
}

export default pageOptions;
</script>