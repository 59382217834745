import Vue from 'vue'
import VueX from 'vuex'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
// 引入全局存储
import store from './store';
// import routes from './config/PageRoutes'
// const router = new VueRouter({
// 	routes
// })
// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueSparkline from 'vue-sparklines'
import Vueditor from '@agametov/vueditor'
import VueHljs from 'vue-hljs'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueUpload from './plugins/upload/'

import VueViewer from 'v-viewer'



// import { VTree, VSelectTree } from './plugins/vue-tree'
import { VTree, VSelectTree } from 'vue-tree-halower'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import DateRangePicker from 'vue2-daterange-picker'
import JSEncrypt from 'jsencrypt';
import { VueAxios } from './api/utils/request'

// 引入组件Vue Router Tab
import RouterTab from 'vue-router-tab'
// plugins css
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-hljs/dist/vue-hljs.min.css'
import '@agametov/vueditor/dist/style/vueditor.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'bootstrap-social/bootstrap-social.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
// color admin css
import './scss/vue.scss'
import 'vue-tree-halower/dist/halower-tree.min.css'

import VModal from 'vue-js-modal'
import App from './App.vue'
//富文本组件
import CKEditor from '@ckeditor/ckeditor5-vue2';
import 'viewerjs/dist/viewer.css'
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';


Vue.use(VueVideoPlayer);
Vue.use(CKEditor);
Vue.config.productionTip = false
Vue.use(VXETable)
Vue.use(VueX)
Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(VueSparkline)
Vue.use(Vueditor)
Vue.use(VueHljs)
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueUpload)
Vue.use(VTree)
Vue.use(VSelectTree)
Vue.use(VModal)
Vue.use(VueDateTimePicker)
Vue.use(VueColorpicker)
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.use(RouterTab)
Vue.use(VueAxios)
Vue.use(VueViewer)
Vue.prototype.$jsEncrypt = JSEncrypt;
Vue.component('v-select', VueSelect);
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('date-range-picker', DateRangePicker)
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VueViewer)
Vue.prototype.baseUrl = 'https://dqhaj.e-clouds.net';
//路由配置、单页 or tabs多页面 模式
import router from './router'

var vue = new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

export default vue;

// export default new Vue({
//   render: h => h(App),
//   router,
//   store
// }).$mount('#app');
