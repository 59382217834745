import axios from 'axios'
import qs from 'qs'
import { VueAxios } from './axios'
import context from '../../main.js'

// 创建 axios 实例
const service = axios.create({
    // baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
    timeout: 15000 // 请求超时时间
})

const baseURL = {
    // property: '/property'
    property: 'https://dqhaj.e-clouds.net'
    // property: 'http://192.168.3.103:83'
}

function baseService(baseURL, headers, data) {
    return service({
        url: baseURL + data.url,
        // url: data.url,
        method: data.method,
        params: data.params,
        data: data.data,
        headers: headers
    })
}

function baseService2(baseURL, data) {
    var headers = {
        'content-type': 'application/x-www-form-urlencoded'
    }
    // 待优化
    if (data.method === 'post' && data.headers === undefined) {
        data.data = qs.stringify(data.data)
    }
    if (data !== undefined && data.headers !== undefined) {
        headers = data.headers
    }

    if (data.needToken) {
        // const token =data.that.$store.getters.getToken;
        const token = context.$store.getters.getToken;
        // const token = Vue.ls.get("token")
        if (token) {
            headers['Authorization'] = 'Bearer ' + token
            return baseService(baseURL, headers, data)
        } else {
            window.location.replace('/user/login')
        }
        // else if (token1) {
        // 	return updateToken().then(res => {
        // 		headers['Authorization'] = 'Bearer ' + res.content.token
        // 		return baseService(baseURL, headers, data)
        // 	}).catch(err => {
        // 		console.log(err)
        //     window.location.replace('/user/login')
        // 	})
        // }
    } else {
        return baseService(baseURL, headers, data)
    }
}

function locpropertyService(data) { // needToken, method, url, params, data
    return baseService2(baseURL.property, data)
}

const err = (error) => {
    var errors = {
        issuccess: false,
        statuscode: 1001,
        content: error,
        errormessage: '网络请求失败，可能是网络断开了'
    }
    // //因Abp框架 登录帐号或密码帐号返回的是400，与网络访问400相同，所以要分别开来
    // if (error.response.status == 400 && error.response.data.error == 'invalid_grant') {
    //     errors = {
    //         issuccess: true,
    //         statuscode: 401,
    //         content: '',
    //         errormessage: '帐号或密码错误'
    //     }
    // }
    return Promise.reject(errors)

}

// 请求拦截器
service.interceptors.request.use(config => {
    return config
}, err)


// 响应拦截器
service.interceptors.response.use((response) => {
    // console.log('响应拦截器', response)
    // if (response.data.errorCode === 401) {
    //     this.$router.push({ name: 'login' })
    // }

    if (!response.data) {
        response.data = {
            isSuccess: false,
            statuscode: 1001,
            content: null,
            errormessage: '没查到数据，可能是操作错了'
        }
    }
    return Promise.resolve(response.data)
}, err)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, service)
    }
}

export {
    installer as VueAxios,
    locpropertyService as propertyService
}
