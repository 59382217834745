<template>
  <div class="uploadWrap">
    <div class="uploadContext">
      <div class="dz-preview dz-processing dz-error dz-complete dz-image-preview" v-for="item in fileList"
        :key="item.src">
        <div class="dz-image">
          <img data-dz-thumbnail="" :src="item.src" @load="revokeSrc(item.src)" />
          <!-- 取消上传标签 -->
          <span v-if="!readOnly" class="cancle" @click="removeImg(item)">×</span>
          <span class="state" v-if="item.isFinished || item.isFail">{{
            item.isFail ? "上传失败" : item.isFinished ? "上传成功" : "上传中"
          }}</span>
        </div>
      </div>
      <div class="dz-preview dz-processing dz-error dz-complete dz-image-preview">
        <div v-if="!readOnly && fileList.length < maxFiles" class="dz-image">
          <input type="file" class="inputNew" @change="uploadChange" :accept="acceptTypes" />
          <div class="dz-error-mark fileUp">
            <svg t="1643011102525" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="1977" width="200" height="200">
              <path
                d="M884.2 909.6H126.8c-38.6 0-70-31.4-70-70V204.6c0-38.6 31.4-70 70-70h328.6c38.6 0 70 31.4 70 70 0 11-9 20-20 20s-20-9-20-20c0-16.6-13.4-30-30-30H126.8c-16.6 0-30 13.4-30 30v635c0 16.6 13.4 30 30 30h757.4c11 0 20 9 20 20s-8.8 20-20 20z"
                fill="#cdcdcd" p-id="1978"></path>
              <path
                d="M884.2 909.6c-11 0-20-9-20-20s9-20 20-20c16.6 0 30-13.4 30-30V428.2c0-16.6-13.4-30-30-30H292.6c-16.6 0-30 13.4-30 30v268.6c0 11-9 20-20 20s-20-9-20-20V428.2c0-38.6 31.4-70 70-70h591.6c38.6 0 70 31.4 70 70v411.4c0 38.6-31.4 70-70 70zM734 295h-178.2c-38.6 0-70-31.4-70-70v-10.4c0-11 9-20 20-20s20 9 20 20v10.4c0 16.6 13.4 30 30 30H734c11 0 20 9 20 20s-9 20-20 20z"
                fill="#cdcdcd" p-id="1979"></path>
              <path
                d="M192.8 909.6h-15.8c-11 0-20-9-20-20s9-20 20-20h15.8c16.6 0 30-13.4 30-30v-168.6c0-11 9-20 20-20s20 9 20 20v168.6c-0.2 38.6-31.4 70-70 70zM818 398.4c-11 0-20-9-20-20v-53.2c0-16.6-13.4-30-30-30h-90.4c-11 0-20-9-20-20s9-20 20-20H768c38.6 0 70 31.4 70 70v53.2c0 11-9 20-20 20z"
                fill="#cdcdcd" p-id="1980"></path>
              <path d="M598.2 764.6c-11 0-20-9-20-20V532.4c0-11 9-20 20-20s20 9 20 20v212.2c0 11-9 20-20 20z"
                fill="#cdcdcd" p-id="1981"></path>
              <path
                d="M683.8 638c-5.2 0-10.2-2-14.2-5.8L584 546.6c-7.8-7.8-7.8-20.4 0-28.2 7.8-7.8 20.4-7.8 28.2 0L698 604c7.8 7.8 7.8 20.4 0 28.2-4 4-9 5.8-14.2 5.8z"
                fill="#cdcdcd" p-id="1982"></path>
              <path
                d="M512.4 638c-5.2 0-10.2-2-14.2-5.8-7.8-7.8-7.8-20.4 0-28.2l85.8-85.8c7.8-7.8 20.4-7.8 28.2 0 7.8 7.8 7.8 20.4 0 28.2l-85.8 85.8c-3.8 4-8.8 5.8-14 5.8z"
                fill="#cdcdcd" p-id="1983"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
// 请求队列
let cbList = [],
  map = new WeakMap();
// 过滤不符合条件的文件
function filterFiles(files, fileTypes, maxSize) {
  return files.filter((file) => {
    let index = file.name.lastIndexOf(".");
    let ext = file.name.slice(index + 1).toLowerCase();
    // 处理jepg各种格式
    if (["jfif", "pjpeg", "jepg", "pjp", "jpg"].includes(ext)) ext = "jpeg";
    if (fileTypes.includes(ext) && file.size <= maxSize * 1024 * 1024) {
      return true;
    } else {
      return false;
    }
  });
}
// 格式化文件名
function formatName(filename) {
  let lastIndex = filename.lastIndexOf(".");
  let suffix = filename.slice(0, lastIndex);
  let fileName = suffix + new Date().getTime() + filename.slice(lastIndex);
  return fileName;
}

import { propertyService } from "@/api/utils/request";

export default {
  name: "upload",
  props: {
    // value: {
    //   type: Array,
    //   default: () => [],
    // },
    value: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 1,
    },
    maxSize: {
      type: Number,
      default: 200,
    },
    fileTypes: {
      type: Array,
      default: () => ["img", "png", "jpeg", "*.*"],
    },
    uploadUrl: {
      type: String,
      default: "/api/file-management/add",
    },
    downloadUrl: {
      type: String,
      default: "/api/file-management/download-info",
    },
    reqCnt: {
      default: 4,
      validator: (val) => {
        return val > 0 && val <= 6;
      },
    },
  },
  data() {
    return {
      fileList: [] /* 文件列表 */,
      maxLen: 6 /* 请求并发数量 */,
      finishCnt: 0 /* 已完成请求数 */,
    };
  },
  //  created() {
  //    console.log('111')
  //  }，
  mounted() {
    this.maxLen = this.reqCnt;
    // if (this.id != undefined && this.id.length > 0) {
    //   this.showImgs(this.id);
    // }
    // console.log("mounted", this.id);
    // this.create();
  },
  //设置默认值
  watch: {
    value: {
      deep: true,
      handler(v) {
        console.log("异步接收值", v);
        // let ids=v.split(",");
        this.showImgs(v);
      },
    },
  },
  methods: {
    //异步接收值
    // initData(ids) {
    //     this.showImgs(ids);
    // },

    //显示图片
    showImgs(ids) {
      console.log("ids", ids);
      let that = this;
      that.fileList = [];
      ids.forEach(function (id) {
        that.fileList.push({
          id: id,
          src: that.baseUrl + '/api/file-management/download/' + id,
        });
        console.log( that.baseUrl + '/api/file-management/download/' + id);
        // return propertyService({
        //   url: that.downloadUrl,
        //   method: "get",
        //   params: { id: id },
        //   needToken: true,
        //   // headers: {
        //   //   "content-type": "multipart/form-data",
        //   // },
        // }).then((res) => {
        //   if (res.success == true) {
        //     // console.log(res);
        //     console.log(res.data.downloadUrl);
        //     console.log('服务器', that.baseUrl+'api/file-management/download/')
        //     that.fileList.push({
        //       id: id,
        //       src: res.data.downloadUrl,
        //     });
        //   }
        // });
      });
    },

    uploadChange(e) {
      console.log(this.maxLen);
      let files = filterFiles(
        [...e.target.files],
        this.fileTypes,
        this.maxSize
      ); //过滤
      this.fileList = this.fileList.concat(
        files.map((file) => {
          // 创建
          let newFile = {};
          newFile.name = formatName(file.name);
          newFile.src = window.URL.createObjectURL(file);
          newFile.progress = 0;
          newFile.abort = false;
          newFile.imgSrc = "";
          // 成功和失败标记
          newFile.isFinished = false;
          newFile.isFail = false;
          // 起始和结束点
          newFile.start = 0;
          newFile.total = file.size;
          // 存入队列后发起上传
          cbList.push(() => this.handleUpload(file, newFile));
          this.request();
          return newFile;
        })
      );
    },

    handleUpload(file, newFile) {
      let chunkSize = 1 * 2048 * 1024; // 切片大小2M
      // 设置文件上传范围
      //   let fd = new FormData();
      let start = newFile.start;
      let total = newFile.total;
      let end = start + chunkSize > total ? total : newFile.start + chunkSize;
      // 上传文件信息
      //   let fileName = newFile.name;
      var fileData = new FormData();
      // 创建一个表单数据
      fileData.append("fileContainerName", "default");
      fileData.append("file", file);
      fileData.append("fileType", 2);
      fileData.append("generateUniqueFileName", false);

      return propertyService({
        url: this.uploadUrl,
        method: "post",
        data: fileData,
        needToken: true,
        headers: {
          "content-type": "multipart/form-data",
        },
        progress: (e, xhr) => {
          // 因为会加上文件名和文件夹信息占用字节,还要等待响应回来，所以取小于等于95
          let proNum = Math.floor(
            ((newFile.start + e.loaded) / newFile.total) * 100
          );
          newFile.progress = Math.min(proNum, 95);
          // 手动中断上传
          if (newFile.abort) {
            xhr.abort();
          }
        },
      })
        .then(
          (res) => {
            if (res.success == false) {
              newFile.isFail = true;
              newFile.err = res.message;
              console.log("upload err", res.message);
              // 建立映射，点击重传使用
              map.set(newFile, file);
            } else {
              if (end >= total) {
                // 跳至100
                newFile.progress = 100;
                // 存url
                newFile.imgSrc = res.imgSrc;
                // 状态改变通知
                newFile.isFinished = true;
                // newFile.res = res;
                newFile.id = res.data.fileInfo.id;
                this.finishCnt++;
                this.fileListChange();
              } else {
                // 新的起始点
                newFile.start = end + 1;
                // 发送剩余资源
                cbList.push(() => this.handleUpload(file, newFile));
              }
            }
          },
          (err) => {
            newFile.isFail = true;
            console.log(err);
            // 建立映射，点击重传使用
            map.set(newFile, file);
          }
        )
        .finally(() => {
          this.maxLen++;
          this.request();
        });

      //   return Ajax({
      //     url: this.uploadUrl,
      //     data: fileData,
      //     progress: (e, xhr) => {
      //       // 因为会加上文件名和文件夹信息占用字节,还要等待响应回来，所以取小于等于95
      //       let proNum = Math.floor(
      //         ((newFile.start + e.loaded) / newFile.total) * 100
      //       );
      //       newFile.progress = Math.min(proNum, 95);
      //       // 手动中断上传
      //       if (newFile.abort) {
      //         xhr.abort();
      //       }
      //     },
      //   })
      //     .then(
      //       (res) => {
      //         if (end >= total) {
      //           // 跳至100
      //           newFile.progress = 100;
      //           // 存url
      //           newFile.imgSrc = res.imgSrc;
      //           // 状态改变通知
      //           newFile.isFinished = true;
      //           this.finishCnt++;
      //           this.fileListChange();
      //         } else {
      //           // 新的起始点
      //           newFile.start = end + 1;
      //           // 发送剩余资源
      //           cbList.push(() => this.handleUpload(file, newFile));
      //         }
      //       },
      //       (err) => {
      //         newFile.isFail = true;
      //         console.log(err);
      //         // 建立映射，点击重传使用
      //         map.set(newFile, file);
      //       }
      //     )
      //     .finally(() => {
      //       this.maxLen++;
      //       this.request();
      //     });
    },
    //请求
    request() {
      while (this.maxLen > 0 && cbList.length) {
        let cb = cbList.shift();
        this.maxLen--;
        cb();
      }
    },
    // 移除url缓存
    revokeSrc(url) {
      window.URL.revokeObjectURL(url);
    },
    // 删除图片
    removeImg(item) {
      item.abort = true;
      let index = this.fileList.indexOf(item);
      if (index !== -1) {
        this.fileList.splice(index, 1);
        this.fileListChange();
      }
    },
    // 上传成功数量变化
    fileListChange() {
      console.log("fileListChange", this.fileList.length);
      let files = [];
      this.fileList.forEach((file) => {
        files.push(file.id);
      });

      this.$emit("finishUpload", files);
      // if (this.finishCnt && this.finishCnt === this.fileList.length) {
      //   this.$emit("finishUpload", this.fileList);
      // }
    },
    // 续传
    continueUpload(newFile) {
      newFile.isFail = false;
      let file = map.get(newFile);
      cbList.push(() => this.handleUpload(file, newFile));
      this.request();
    },
  },
  computed: {
    // 设置文件类型
    acceptTypes() {
      return this.fileTypes.map((type) => "image/" + type).join(",");
    },
  },
};
</script>
<style>
/* 上传 */
.uploadWrap {
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
  position: relative;
  box-sizing: border-box;
  padding: 5px;
}

.fileUp {
  opacity: 0;
  position: absolute;
  width: 73px;
  height: 27px;
}

.upload {
  padding: 5px 5px 5px 0px;
}

.upload button {
  background-color: #409eff;
  border: none;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 5px 10px;
  color: white;
  outline: none;
  cursor: pointer;
}

/* 提示 */
.tips {
  display: inline-block;
  font-size: 10px;
  color: #6b6972;
}

/* 标签 */
.imgWrap {
  list-style: none;
  margin: 10px 0;
  width: 400px;
  min-width: 400px;
  box-sizing: border-box;
  border: 1px solid #c4d0dc;
  position: relative;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  padding: 10px;
}

.imgWrap :hover .flag {
  display: none;
}

.dz-image .state {
  position: absolute;
  bottom: 0;
  text-align: center;
  background-color: #15d169;
  width: 100%;
  color: white;
}

.imgWrap .left {
  margin-right: 10px;
}

.imgWrap .right {
  width: 310px;
  color: #647090;
  font-size: 14px;
  /* 垂直元素排列 */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  overflow: hidden;
}

.dz-image .cancle {
  color: #999;
  font-size: 25px;
  position: absolute;
  right: 4px;
  top: -4px;
}

.imgWrap .left img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.imgWrap .right .name {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.imgWrap .right .num {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.imgWrap .right .bar {
  height: 5px;
  background-color: #409eff;
  border-radius: 5px;
}

.imgWrap .right .cancle {
  color: #999;
  font-size: 25px;
  position: absolute;
  right: 4px;
  top: -4px;
}

/* 勾 */
.flag {
  position: absolute;
  width: 0px;
  height: 0px;
  border: 20px solid #13ce66;
  top: 0;
  right: 0;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.flag span {
  position: absolute;
  font-size: 20px;
  right: -16px;
  top: -22px;
}

.redBd {
  border-color: orangered orangered transparent transparent;
}

.greenBd {
  border-color: #15d169 #15d169 transparent transparent;
}

.defaultBd {
  border-color: #4078f1 #4078f1 transparent transparent;
}

ul {
  list-style: none;
  padding: 0;
}

.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translate(150, 180);
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
}

.v-move {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.v-leave-active {
  position: absolute;
}

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.uploadContext,
.uploadContext * {
  box-sizing: border-box;
}

.uploadContext {
  min-height: 150px;
  /* border: 2px solid rgba(0, 0, 0, 0.3); */
  background: white;
  padding: 10px 10px;
}

.uploadContext.dz-clickable {
  cursor: pointer;
}

.uploadContext.dz-clickable * {
  cursor: default;
}

.uploadContext.dz-clickable .dz-message,
.uploadContext.dz-clickable .dz-message * {
  cursor: pointer;
}

.uploadContext.dz-started .dz-message {
  display: none;
}

.uploadContext.dz-drag-hover {
  border-style: solid;
}

.uploadContext.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.uploadContext .dz-message {
  text-align: center;
  margin: 2em 0;
}

.uploadContext .dz-message .dz-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.uploadContext .dz-preview {
  border-radius: 5px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
  background: rgb(250, 250, 250);
}

.uploadContext .dz-preview:hover {
  z-index: 1000;
  background: rgb(240, 240, 240);
}

.uploadContext .dz-preview:hover .dz-details {
  opacity: 1;
}

.uploadContext .dz-preview.dz-file-preview .dz-image {
  /* border-radius: 5px; */
  width: 100%;
  /* background: #999;
  background: linear-gradient(to bottom, #eee, #ddd); */
}

.inputNew {
  opacity: 0;
  position: absolute;
  width: 120px;
  height: 120px;
  cursor: pointer;
}

.uploadContext .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.uploadContext .dz-preview.dz-image-preview {
  /* background: rgb(245, 245, 245); */
}

.uploadContext .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.uploadContext .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.uploadContext .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.uploadContext .dz-preview:hover .dz-details {
  opacity: 1;
}

.uploadContext .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.uploadContext .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.uploadContext .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.uploadContext .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.uploadContext .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.uploadContext .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.uploadContext .dz-preview .dz-details .dz-filename span,
.uploadContext .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.uploadContext .dz-preview:hover .dz-image img {
  /* -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px); */
}

.uploadContext .dz-preview .dz-image {
  border-radius: 10px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.uploadContext .dz-preview .dz-image img {
  height: inherit;
  display: block;
  width: auto;
}

.uploadContext .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.uploadContext .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.uploadContext .dz-preview .dz-success-mark,
.uploadContext .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.uploadContext .dz-preview .dz-success-mark svg,
.uploadContext .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.uploadContext .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.uploadContext .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.uploadContext .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.uploadContext .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.uploadContext .dz-preview.dz-error .dz-error-message {
  display: block;
}

.uploadContext .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropuploadContextzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}

.uploadContext .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}
</style>