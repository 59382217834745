const state = {
	isLogin: sessionStorage.getItem(`isLogin`) || false,
	token: sessionStorage.getItem(`token`) || ``,
	userInfo: {},
	role: sessionStorage.getItem(`role`) || -1
}
//这里是get方法
const getters = {
	// 全局配置
	themeColor: state => {
		return state.themeColor;
	},
	// 全局配置
	globalConfig: state => {
		return state.globalConfig;
	},
	// 获取网络状态
	networkStatus: state => {
		return state.networkState;
	},
	// 判断用户是否登录
	hasLogin: state => state.isLogin && !!state.token,

	getToken: state => state.token,

	getRole: state => state.role,

	userInfo: state => {
		if (state.userInfo.username == undefined) {
			let u = sessionStorage.getItem(`userInfo`);
			if (u == undefined) return {};
			state.userInfo = JSON.parse(u);
		}
		return state.userInfo;
	}
};

const actions = {

};
//这里是set方法
const mutations = {
	setUserInfo(state, data) {
		state.isLogin = true;
		state.userInfo = data;
		state.token = data.token;
		sessionStorage.setItem('token', state.token);
		sessionStorage.setItem('isLogin', state.isLogin);
		sessionStorage.setItem('userInfo', JSON.stringify(state.userInfo));
	},
	//缓存Token
	setToken(state, data) {
		state.token = data;
		sessionStorage.setItem('token', state.token);
	},
	logout(state) {
		state.token = '';
		state.userInfo = {};
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('isLogin');
		sessionStorage.removeItem('userInfo');
	},
	setUserRole(state, data) {
		state.role = data;
		sessionStorage.setItem('role', state.role);
	}
};

export default {
	state,
	getters,
	actions,
	mutations
}