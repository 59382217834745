import Vue from 'vue'
import VueRouter from 'vue-router'

//独立显示，通用页面
import Login from '../pages/system/Login.vue'



/*
 * 单页面模式
 */
// import Home from '../pages/Home.vue'
// import About from '../pages/About.vue'


// const routes = [
//     { path: '/', component: Home },
//     { path: '/about', component: About },
//     { path: '/login', component: Login },
// ]



/*
 * Tabs多页面模式
 */

// RouterTab 内置路由
import {
    RouterTabRoutes
} from 'vue-router-tab'
// 引入布局框架组件
import Frame from '../components/layout/Frame.vue'
// 异步加载页面组件
const importPage = view => () =>
    import( /* webpackChunkName: "p-[request]" */ `../pages/${view}.vue`)

const routes = [{
        path: '/',
        component: Frame,
        children: [
            // 引入 RouterTab 内置路由以支持 iframe 页签
            ...RouterTabRoutes,
            {
                path: '/', // 默认页和父级路由一致
                name: 'Home',
                component: importPage('Home'),
                meta: {
                    title: '首页', // 页签标题
                    closable: false,
                    icon: 'fa fa-home'
                }
            },
            {
                path: '/about',
                name: 'About',
                component: importPage('About'),
                meta: {
                    icon: 'fa fa-rss-square',
                    title: '关于' // 页签标题
                }
            },
            {
                path: '/YinShi',
                name: 'YinShi',
                component: importPage('SystemBasic/YinShi'),
                meta: {
                    icon: 'fa fa-rss-square',
                    title: '视频墙' // 页签标题
                }
            },
            {
                path: '/map',
                name: 'map',
                component: importPage('examples/map'),
                meta: {
                    title: '地图' // 页签标题
                }
            },
            {
                path: '/upload',
                name: 'upload',
                component: importPage('examples/upload'),
                meta: {
                    title: '图片上传' // 页签标题
                }
            },
            {
                path: '/gallery',
                name: 'gallery',
                component: importPage('examples/gallery'),
                meta: {
                    title: '图片墙' // 页签标题
                }
            },
            {
                path: '/chargepw',
                name: 'chargepw',
                component: importPage('system/ChargePw'),
                meta: {
                    title: '修改密码' // 页签标题
                }
            },
            {
                path: '/CompanyInfomation',
                name: 'CompanyInfomation',
                component: importPage('Company/Basis/CompanyInfomation'),
                meta: {
                    title: '企业信息编辑' // 页签标题
                }
            },
            {
                path: '/CompanyInfo',
                name: 'CompanyInfo',
                component: importPage('Company/Basis/CompanyInfo'),
                meta: {
                    icon: "fa fa-building",
                    title: '企业信息' // 页签标题
                }
            },
            //工业企业
            {
                path: '/CompanyList',
                name: 'CompanyList',
                component: importPage('Company/Basis/CompanyList'),
                meta: {
                    icon: "fa fa-th-list",
                    title: '工业企业' // 页签标题
                }
            },
            //沿街店铺
            {
                path: '/IndustryCompany',
                name: 'IndustryCompany',
                component: importPage('Company/Basis/IndustryCompany'),
                meta: {
                    icon: "fa fa-th-list",
                    title: '非工业企业' // 页签标题
                }
            },
            //非工业企业
            {
                path: '/ShopList',
                name: 'ShopList',
                component: importPage('Company/Basis/ShopList'),
                meta: {
                    icon: "fa fa-th-list",
                    title: '沿街店铺' // 页签标题
                }
            },
            //回收站
            {
                path: '/Recycle',
                name: 'Recycle',
                component: importPage('Company/Basis/Recycle'),
                meta: {
                    icon: "fa fa-trash-alt",
                    title: '回收站' // 页签标题
                }
            },
            //企业用户
            {
                path: '/CompanyUserList',
                name: 'CompanyUserList',
                component: importPage('SystemBasic/CompanyUser/CompanyUserList'),
                meta: {
                    icon: "fa fa-user-circle",
                    title: '用户列表' // 页签标题
                }
            },
            {
                path: '/CompanyUser',
                name: 'CompanyUser',
                component: importPage('SystemBasic/CompanyUser/CompanyUser'),
                meta: {
                    icon: "fa fa-user-circle",
                    title: '企业用户' // 页签标题
                }
            },
            {
                path: '/Test',
                name: 'Test',
                component: importPage('SystemBasic/CompanyUser/Test'),
                meta: {
                    icon: "fa fa-caret-square-right",
                    title: '视频墙' // 页签标题
                }
            },

            //区域管理
            {
                path: '/Region',
                name: 'Region',
                component: importPage('SystemBasic/Region/Region'),
                meta: {
                    title: '区域信息' // 页签标题
                }
            },
            {
                path: '/RegionList',
                name: 'RegionList',
                component: importPage('SystemBasic/Region/RegionList'),
                meta: {
                    icon: "fa fa-map",
                    title: '区域列表' // 页签标题
                }
            },
            //容压器列表
            {
                path: '/Pressurevessel',
                name: 'Pressurevessel',
                component: importPage('SystemBasic/Pressurevessel/Pressurevessel'),
                meta: {
                    icon: "fa fa-fire-extinguisher",
                    title: '容压器' // 页签标题
                }
            },
            {
                path: '/PressurevesselList',
                name: 'PressurevesselList',
                component: importPage('SystemBasic/Pressurevessel/PressurevesselList'),
                meta: {
                    icon: "fa fa-fire-extinguisher",
                    title: '容压器列表' // 页签标题
                }
            },

            //政策新闻列表
            {
                path: '/NewsList',
                name: 'NewsList',
                component: importPage('News/NewsList'),
                meta: {
                    icon: "fa fa-newspaper",
                    title: '新闻列表' // 页签标题
                }
            },
            //政策新闻
            {
                path: '/News',
                name: 'News',
                component: importPage('News/News'),
                meta: {
                    icon: "fa fa-newspaper",
                    title: '政策新闻' // 页签标题
                }
            },
            //安全检查列表
            {
                path: '/SecurityCheckList',
                name: 'SecurityCheckList',
                component: importPage('SecurityCheck/SecurityCheckList'),
                meta: {
                    icon: "fa fa-th-list",
                    title: '安全检查列表' // 页签标题
                }
            },
            //安全检查编辑页面
            {
                path: '/SecurityCheck',
                name: 'SecurityCheck',
                component: importPage('SecurityCheck/SecurityCheck'),
                meta: {
                    icon: "fa fa-shield-alt",
                    title: '安全检查' // 页签标题
                }
            },

            // 监控地图
            {
                path: '/CameraMap',
                name: 'CameraMap',
                component: importPage('Camera/CameraMap'),
                meta: {
                    icon: "fa fa-camera-retro",
                    title: '监控地图' // 页签标题
                }
            },
            // 网格长地图轨迹
            {
                path: '/GridheadMapLoca',
                name: 'GridheadMapLoca',
                component: importPage('GridheadLoca/GridheadMapLoca'),
                meta: {
                    icon: "fa fa-map-marker-alt",
                    title: '轨迹图' // 页签标题
                }
            },
            {
                path: '/page/:id',
                component: importPage('Page'),
                meta: {
                    title: '页面', // 页签标题
                    icon: 'icon-user', // 页签图标，可选
                    tabClass: 'custom-tab', // 自定义页签 class，可选
                    tips: '这是一个页面', // 页签提示，可选，如未设置则跟 title 一致
                    key: 'path', // 路由打开页签规则，可选
                    closable: false // 页签是否允许关闭，默认 `true`
                }
            },
            {
                path: '/404',
                component: importPage('404'),
                meta: {
                    title: '找不到页面',
                    icon: 'icon-page'
                }
            }
        ]
    },

    {
        path: '/login',
        component: Login
    },


    {
        path: '*',
        redirect: '404'
    }
]

//**********  vue-router-tab end



Vue.use(VueRouter)
// import routes from '../config/PageRoutes'
const router = new VueRouter({
    routes
})

export default router