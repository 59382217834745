<script>
const sidebarMenu = [
  { path: "/", icon: "fa fa-home", title: "首页" },
  {
    icon: "fa fa-building",
    title: "企业管理",
    children: [
      {
        path: "/CompanyList",
        icon: "fa fa-th-list",
        title: "工业企业",
      },
      {
        path: "/IndustryCompany",
        icon: "fa fa-th-list",
        title: "非工业企业",
      },
      {
        path: "/ShopList",
        icon: "fa fa-th-list",
        title: "沿街店铺",
      },
      {
        path: "/Recycle",
        icon: "fa fa-trash-alt",
        title: "回收站",
      },
      {
        path: "/CompanyUserList",
        icon: "fa fa-user-circle",
        title: "用户管理",
      },
    ],
  },
  // {
  //   path: "/menu",
  //   icon: "fa fa-align-left",
  //   title: "用户管理",
  //   children: [
  //     // { path: "/map", title: "地图" },
  //     { path: "/upload", title: "图片上传" },
  //     { path: "/chargepw", icon: "fa fa-rocket", title: "修改密码" },
  //   ],
  // },
  {
    path: "/GridheadMapLoca",
    icon: "fa fa-random",
    title: "网格员工作轨迹",
  },
  { path: "/SecurityCheckList", icon: "fa fa-shield-alt", title: "安全检查" },
  { path: "/YinShi", icon: "fa fa-camera-retro", title: "视频墙" },
  // {
  //   icon: "fa fa-map-marker-alt",
  //   title: "监控管理",
  //   children: [
  //     {
  //       path: "/CameraMap",
  //       icon: "fa fa-camera-retro",
  //       title: "监控地图",
  //     },

  //     {
  //       path: "/Test",
  //       icon: "fa fa-caret-square-right",
  //       title: "视频墙",
  //     },
  //   ],
  // },

  {
    icon: "fa fa-cog",
    title: "系统设置",
    children: [
      // {
      //   path: "/GridheadList",
      //   icon: "fa fa-universal-access",
      //   title: "网格长管理",
      // },
      {
        path: "/RegionList",
        icon: "fa fa-map",
        title: "区域列表",
      },
      // { path: "/Region", icon: "fa fa-th", title: "区域信息" },
      //      {
      //   path: "/Pressurevessel",
      //   icon: "fa fa-rocket",
      //   title: "容压器",
      // },
      {
        path: "/PressurevesselList",
        icon: "fa fa-fire-extinguisher",
        title: "容压器列表",
      },
    ],
  },

  {
    path: "/NewsList",
    icon: "fa fa-newspaper",
    title: "政策新闻",
  },

  { path: "/about", icon: "fa fa-rss-square", title: "关于" },
];

export default sidebarMenu;
</script>
